/**
 * Renders a box with a title and an OverviewSectionProcess component for each provided section process.
 * @param {Object} props
 * @param {string} props.title Name of Overview Section.
 * @param {SectionProcess[]} props.sectionProcesses 
 * @returns 
 */
export default function OverviewSection({ title, children }) {
  return (
    <div className="overview-section">
      <h2>{ title }</h2>
      { children }
    </div>
  )
}
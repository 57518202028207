/**
 * Renders a horizontal rule element.
 * @returns 
 */
export default function HorizontalRule() {
  return (
    <hr style={{
      color: '#000',
      backgroundColor: '#000',
      height: '1px',
      width: '100%'
    }} />
  )
}
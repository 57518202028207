import { createContext, useContext, useReducer } from "react";
import { loadAllStatuses, setIsLoading, updateDataRefreshStatus, updateProcessCurrentStatus, updateProcessPreviousStatus } from "./actions/processStatus";

const ProcessStatusContext = createContext(null)
const ProcessStatusDispatchContext = createContext(null)

/**
 * Provides nested children with access to Vendor data and dispatch contexts.
 * @param {Object} props
 * @param {any} props.children Child elements. 
 * @returns 
 */
export function ProcessStatusProvider({ children }) {
  const [ processStatus, dispatch ] = useReducer(processStatusReducer, initialState)

  return (
    <ProcessStatusContext.Provider value={processStatus}>
      <ProcessStatusDispatchContext.Provider value={dispatch}>
        { children }
      </ProcessStatusDispatchContext.Provider>
    </ProcessStatusContext.Provider>
  )
}

/**
 * Returns vendor data context.
 * @returns 
 */
export function useProcessStatus() {
  return useContext(ProcessStatusContext)
}

/**
 * Returns vendor data dispatch context.
 */
export function useProcessStatusDispatch() {
  return useContext(ProcessStatusDispatchContext)
}

function processStatusReducer(state, action) {
  switch(action.type) {
    case "SET_IS_LOADING": return setIsLoading(state, action)
    case "LOAD_ALL_STATUSES": return loadAllStatuses(state, action)
    case "UPDATE_CURRENT_STATUS": return updateProcessCurrentStatus(state, action)
    case "UPDATE_PREVIOUS_STATUS": return updateProcessPreviousStatus(state, action)
    case "UPDATE_DATA_REFRESH_STATUS": return updateDataRefreshStatus(state, action)
    default: return state
  }
}

const initialState = {
  vendorMapping: {
    isLoading: true
  },
  estimatedImportCosts: {
    currentStatus: {
      id: null,
      activeStep: {},
      stepHistory: [],
      processType: null
    },
    previousStatus: {
      id: null,
      activeStep: {},
      stepHistory: [],
      processType: null
    }
  },
  freightChargeGaps: {
    currentStatus: {
      id: null,
      activeStep: {},
      stepHistory: []
    },
    previousStatus: {
      id: null,
      activeStep: {},
      stepHistory: []
    }
  },
  approvals: {
    currentStatus: {
      id: null,
      activeStep: {},
      stepHistory: []
    },
    previousStatus: {
      id: null,
      activeStep: {},
      stepHistory: []
    }
  },
  dataMaintenance: {
    isLoading: false
  }
}
import { useCallback, useEffect, useMemo, useState } from "react";
import ProcessFlowStep from "../../components/process/ProcessFlowStep";
import { useProcessStatus, useProcessStatusDispatch } from "../../contexts/ProcessStatus";
import ProcessDatepicker from "../../components/process/ProcessDatepicker";
import ProcessButton from "../../components/process/ProcessButton";
import Modal from "../Modal";
import StaticStatusMessage from "../../components/StaticStatusMessage";
import LiveProcessStatusIndicator from "../../components/LiveProcessStatusIndicator";
import moment from "moment";
import { post, sendDelete } from "../../lib/api";
import { useMsal } from "@azure/msal-react";

export default function ApprovalsReplacementCostsCalculation({ isVisible }) {

  const { instance, accounts } = useMsal()
  const { approvals, dataMaintenance } = useProcessStatus()
  const dispatch = useProcessStatusDispatch()
  const { currentStatus, previousStatus, statusText } = approvals
  const { activeStep, stepHistory, dates } = currentStatus
  const { dates: previousDates } = previousStatus
  const { step, start, end, errors, errorCount } = activeStep
  
  const [ startDate, setStartDate ] = useState(null)
  const [ endDate, setEndDate ] = useState(null)

  const [ startErrors, setStartErrors ] = useState([])
  const [ showError, setShowError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ tempStatus, setTempStatus ] = useState(null)
  const [ showRestartNotification, setShowRestartNotification ] = useState(false)

  const isComplete = useMemo(() => stepHistory.find(step => step.step === 'calculation'), [stepHistory])
  const defaultStartDate = useMemo(() => previousDates ? moment(previousDates?.end).add(1, 'day').toDate() : null, [previousDates])

  const onClick = useCallback(async () => {
    if (!startDate || !endDate) return
    if (startDate > endDate) { setShowError(true) ; return }
    setIsLoading(true)
    setTempStatus('Calculating landed and replacement costs...')

    const { errors, errorCount, ...activeStepData } = activeStep
    if (!activeStepData.hasOwnProperty('start')) activeStepData.start = {}
    dispatch({
      type: 'UPDATE_CURRENT_STATUS',
      payload: { 
        process: 'approvals', 
        status: { ...currentStatus, activeStep: { ...activeStepData, step: 'calculation' } }
      }})
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await post('approvals-replacement-costs', idToken, JSON.stringify({
      CREATED_AT: moment().toISOString(),
      START_DATE: moment(startDate).format('YYYY-MM-DD'),
      END_DATE: moment(endDate).format('YYYY-MM-DD')
    }))
    if (!response.ok) {
      const json = await response.json()
      setStartErrors([json.error])
    }
    setIsLoading(false)
    setTempStatus(null)
  }, [startDate, endDate, instance, accounts, dispatch, activeStep, currentStatus])

  const onProcessRestart = useCallback(() => {  
    setShowRestartNotification(true)
  }, [])

  const onRestartConfirm = useCallback(async () => {
    setIsLoading(true)
    setTempStatus('Restarting process...')
    setShowRestartNotification(false)

    dispatch({
      type: 'UPDATE_CURRENT_STATUS',
      payload: { 
        process: 'approvals', 
        status: { ...currentStatus, activeStep: { step: 'calculation', start: {} } }
      }})

    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await sendDelete(`approvals-replacement-costs/${currentStatus.id}`, idToken)
    if (response.ok) await onClick()
    setIsLoading(false)
    setTempStatus(null)
  }, [accounts, currentStatus, instance, dispatch, onClick])

  useEffect(() => {
    if (!startDate && defaultStartDate) setStartDate(defaultStartDate)
  }, [startDate, defaultStartDate])

  return (
    <ProcessFlowStep
      stepText='1. Calculate Landed and Replacement Costs'
      hidden={!isVisible}
      isActive={step === 'calculation' || step === undefined}
      isHistorical={isComplete}>
      { 
        isComplete && 
        <StaticStatusMessage>
          { `Calculations last completed by ${isComplete.end.user}` }
          <br />
          { new Date(isComplete.end.time).toLocaleString() }
          <br />
          for { `${moment(dates?.start).format('MM/DD/YYYY')} - ${moment(dates?.end).format('MM/DD/YYYY')}` }
          <br />
          { isComplete.file }
        </StaticStatusMessage>
      }
      <ProcessDatepicker 
        labelText="Start Date" 
        date={startDate || defaultStartDate} 
        handler={date => setStartDate(date)}
        startDate={startDate || defaultStartDate}
        endDate={endDate}
        selectsStart={true}
        selectsEnd={false} />
      <ProcessDatepicker 
        labelText="End Date" 
        date={endDate} 
        handler={date => setEndDate(date)}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        selectsStart={false}
        selectsEnd={true} />
      <ProcessButton 
        buttonText="Run Calculations" 
        handler={isComplete ? onProcessRestart : onClick} 
        isDisabled={dataMaintenance.isLoading || (startDate === null || endDate === null) || isLoading || (start && !end && !errors)} />
      {
        (isLoading || !isComplete) &&
        <LiveProcessStatusIndicator
          type='process'
          isLoading={isLoading || (start && !end && !errors)}
          errorCount={startErrors.length > 0 ? startErrors.length : errorCount}
          errors={isLoading ? [] : startErrors.length > 0 ? startErrors : errors}
          statusText={tempStatus || statusText} />
      }
      <Modal
        show={showError}
        onClose={() => setShowError(false)}
        type='error'
        headerText='Error'>
        <p>Start Date must be prior to End Date.</p>
      </Modal>
      <Modal 
        show={showRestartNotification} 
        onClose={() => setShowRestartNotification(false)} 
        type='confirm' 
        onConfirm={onRestartConfirm} 
        headerText={'Warning'}>
        <span>This will restart the current process.<br />Do you wish to calculate the data again?</span>
      </Modal> 
    </ProcessFlowStep>
  )
}
import { Helmet } from 'react-helmet'
import { useState, useEffect } from 'react'
import { useProcessStatus } from '../contexts/ProcessStatus'
import MaintenanceHistoryContainer from '../containers/MaintenanceHistoryContainer'
import EstimatedCostsExportData from '../containers/estimatedimportcosts/EstimatedCostsExportData'
import ProgressBar from '../components/progress/ProgressBar'
import EstimatedCostsImportData from '../containers/estimatedimportcosts/EstimatedCostsImportData'
import EstimatedCostsMaintenanceHistory from '../components/maintenance/EstimatedCostsMaintenanceHistory'
import Modal from '../containers/Modal'
import AutomationToggle from '../components/AutomationToggle'

/**
 * Renders Estimated Import Costs process flow steps and maintenance history.
 * @returns 
 */
export default function EstimatedImportCosts() {

  const { estimatedImportCosts } = useProcessStatus()
  const { currentStatus, previousStatus } = estimatedImportCosts

  const [ visibleStep, setVisibleStep ] = useState(currentStatus.activeStep.step || 'exportCsv')
  const [ percentage, setPercentage ] = useState('10%')
  const [ showSuccess, setShowSuccess ] = useState(false)

  useEffect(() => {
    const { activeStep } = currentStatus
    activeStep.step === 'importCsv' ?
      setPercentage('90%') :
      setPercentage('10%')
  }, [currentStatus])

  useEffect(() => {
    const { activeStep } = currentStatus
    const { step } = activeStep
    if (step) setVisibleStep(step)
  }, [currentStatus])

  return (
    <div>
      <Helmet>
        <title>Landed Cost - Estimated Import Cost Components</title>
      </Helmet>
      <h1>Estimated Import Cost Maintenance</h1>
      <section id='estimated-import-costs'>
        <AutomationToggle />
        <ProgressBar steps={[
          { stepText: 'exportCsv', onClick: () => setVisibleStep('exportCsv'), complete: true },
          { stepText: 'importCsv', onClick: () => setVisibleStep('importCsv'), complete: currentStatus.stepHistory.find(step => step.step === 'importCsv') },
        ]} percentage={percentage} />
        <div className='process-flow-container'>
          { 
            visibleStep === 'exportCsv' ?
              <EstimatedCostsExportData /> :
              <EstimatedCostsImportData completionHandler={() => {setShowSuccess(true); setVisibleStep('exportCsv')}} /> 
          }
        </div>
        <MaintenanceHistoryContainer MaintenanceHistoryComponent={EstimatedCostsMaintenanceHistory} previousStatus={previousStatus} currentStatus={currentStatus} />
        <Modal show={showSuccess} onClose={() => setShowSuccess(false)} type='success'>
          <h1 style={{textAlign: 'center'}}>Import Successful</h1>
        </Modal>
      </section>
    </div>
  )
}
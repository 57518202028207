import { useCallback, useMemo, useState } from "react";
import ProcessFlowStep from "../../components/process/ProcessFlowStep";
import { useProcessStatus, useProcessStatusDispatch } from "../../contexts/ProcessStatus";
import ProcessButton from "../../components/process/ProcessButton";
import StaticStatusMessage from "../../components/StaticStatusMessage";
import LiveProcessStatusIndicator from "../../components/LiveProcessStatusIndicator";
import moment from "moment";
import { put, patch } from "../../lib/api";
import { useMsal } from "@azure/msal-react";
import ProcessFilepicker from "../../components/process/ProcessFilepicker";

export default function ApprovalsReplacementCostsImportData({ isVisible, replayHandler }) {

  const { instance, accounts } = useMsal()
  const { approvals, dataMaintenance } = useProcessStatus()
  const dispatch = useProcessStatusDispatch()
  const { currentStatus, statusText, previousStatus } = approvals
  const { activeStep, stepHistory, dates } = currentStatus
  const { step, start, end, errors, errorCount } = activeStep
  
  const [ isLoading, setIsLoading ] = useState(false)
  const [ tempStatus, setTempStatus ] = useState(null)
  const [ importFile, setImportFile ] = useState(null)
  const [ uploadErrors, setUploadErrors ] = useState(null)

  const isComplete = useMemo(() => stepHistory.find(step => step.step === 'importCsv'), [stepHistory])
  const previousRun = useMemo(() => isComplete || previousStatus.stepHistory.find(step => step.step === 'importCsv'), [isComplete, previousStatus])

  const onFileUpload = useCallback(async () => {
    if (importFile === null) return
    setUploadErrors(null)
    setIsLoading(true)
    setTempStatus('Import in progress...')

    if (!isComplete) {
      const { errors, errorCount, ...activeStepData } = activeStep
      if (!activeStepData.hasOwnProperty('start')) activeStepData.start = {}
      dispatch({
        type: 'UPDATE_CURRENT_STATUS',
        payload: { 
          process: 'approvals', 
          status: { ...currentStatus, activeStep: activeStepData }
        }})  
    }

    const fileData = new FormData()
    fileData.append('file', importFile, importFile.name)
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await put(`approvals-replacement-costs/upload?replay=${!!isComplete}`, idToken, fileData)
    if (!response.ok) {
      const json = await response.json()
      setUploadErrors([json.error])
    }
    setImportFile(null)
    document.querySelector('.process-filepicker input').value = null
    setTempStatus(null)
    setIsLoading(false)
  }, [importFile, instance, accounts, dispatch, activeStep, currentStatus, isComplete])

  const onFileChange = useCallback(event => {
    setImportFile(event.target.files[0])
  }, [])

  const onCancelClick = useCallback(() => {
    setImportFile(null)
    document.querySelector('.process-filepicker input').value = null
  }, [])

  const onSkipClick = useCallback(async () => {
    if (isComplete) {
      replayHandler()
      return
    }
    setUploadErrors(null)
    setIsLoading(true)
    setTempStatus('Skipping to approval step...')

    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    await patch(`approvals-replacement-costs/${currentStatus.id}?step=importCsv`, idToken, JSON.stringify({
      IMPORT_SKIPPED: true,
      IMPORT_SKIPPED_AT: moment().toISOString()
    }))

    const { errors, errorCount, ...activeStepData } = activeStep
    if (!activeStepData.hasOwnProperty('start')) activeStepData.start = {}
    activeStepData.skipped = true
    dispatch({
      type: 'UPDATE_CURRENT_STATUS',
      payload: { 
        process: 'approvals', 
        status: { ...currentStatus, activeStep: activeStepData }
      }})

    setIsLoading(false)
    setTempStatus(null)
  }, [accounts, activeStep, currentStatus, dispatch, instance, isComplete, replayHandler])

  return (
    <ProcessFlowStep
      stepText='3. Import Manual Replacement Cost Overrides'
      hidden={!isVisible}
      isActive={step === 'importCsv'}
      isHistorical={isComplete}>
      { 
        previousRun && 
        <StaticStatusMessage>
          { `Replacement cost overrides last ${previousRun.skipped ? 'skipped by' : 'completed by'} ${previousRun.end.user}`}
          <br />
          { new Date(previousRun.end.time).toLocaleString() }
          <br />
          for { `${moment(isComplete ? dates?.start : previousStatus.dates?.start).format('MM/DD/YYYY')} - ${moment(isComplete ? dates?.end : previousStatus.dates?.end).format('MM/DD/YYYY')}` }
          <br />
          { previousRun.file }
        </StaticStatusMessage>
      }
      <ProcessFilepicker
        fileName={importFile?.name || null}
        cancelHandler={onCancelClick}
        changeHandler={onFileChange}
        uploadHandler={onFileUpload}
        isDisabled={dataMaintenance.isLoading || isLoading || (start && !end && !errors)} />
      <ProcessButton 
        buttonText="Skip to Approvals >>" 
        handler={onSkipClick} 
        isDisabled={
          dataMaintenance.isLoading || 
          isLoading || 
          (start && !end && !errors) ||
          (isComplete?.skipped === false)} />
      {
        (isLoading || !isComplete || (isComplete && step === 'importCsv' && start && !end)) &&
        <LiveProcessStatusIndicator
          type='process'
          isLoading={isLoading || (start && !end && !errors)}
          errorCount={errorCount}
          errors={isLoading ? [] : uploadErrors || errors}
          statusText={tempStatus || statusText} />
      }
    </ProcessFlowStep>
  )
}
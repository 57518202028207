import DataGridHeaderRow from "./DataGridHeaderRow";
import DataGridRow from "./DataGridRow";

/**
 * Renders a table of records.
 * @param {Object} props
 * @param {Record[]} props.records Array of records to render. 
 * @returns 
 */
export default function DataGrid({ records = [], maxHeight }) {
  return (
    <div className='data-grid' style={{ maxHeight }}>
      <table className='data-grid-table'>
        <thead>
          <DataGridHeaderRow />
        </thead>
        <tbody>
        {
          records.map(record => <DataGridRow key={record.id} record={record} />)
        }
        </tbody>
      </table>
    </div>
  )
}
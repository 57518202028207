import DataGridCell from "./DataGridCell";

/**
 * Renders a table row with DataGridCell components for each record property.
 * @param {Object} props
 * @param {Record} props.record Record data to render. 
 * @returns 
 */
export default function DataGridRow({ record }) {
  return (
    <tr id={record.id} className='data-grid-row'>
      <DataGridCell content={record.LOGON} />
      <DataGridCell content={record.VENDOR_NUMBER} />
      <DataGridCell content={record.VENDOR_NAME} />
      <DataGridCell content={record.CHARGE_TYPE} isEditable={true} recordKey={record.id} type='select' />
      <DataGridCell content={record.CREATED_BY} />
      <DataGridCell content={record.CREATED_DATE} type='date' />
      <DataGridCell content={record.UPDATED_BY} />
      <DataGridCell content={record.UPDATED_DATE} type='date' />
    </tr>
  )
}
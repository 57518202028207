/**
 * Renders a header cell element.
 * @param {Object} props
 * @param {any} props.content Child content to render inside cell. 
 * @returns 
 */
export default function DataGridHeaderCell({ content }) {
  return (
    <th className='data-grid-header-cell'>
      { content }
    </th>
  )
}
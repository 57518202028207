import { Helmet } from 'react-helmet'
import AutomationToggle from '../components/AutomationToggle'
import ProgressBar from '../components/progress/ProgressBar'
import { useState, useEffect, useCallback } from 'react'
import { useProcessStatus } from '../contexts/ProcessStatus'
import MaintenanceHistoryContainer from '../containers/MaintenanceHistoryContainer'
import ApprovalsReplacementCostMaintenanceHistory from '../components/maintenance/ApprovalsReplacementCostMaintenanceHistory'
import Modal from '../containers/Modal'
import ApprovalsReplacementCostsCalculation from '../containers/approvalsreplacementcosts/ApprovalsReplacementCostsCalculation'
import ApprovalsReplacementCostsPushTrilogie from '../containers/approvalsreplacementcosts/ApprovalsReplacementCostsPushTrilogie'
import ApprovalsReplacementCostsExportData from '../containers/approvalsreplacementcosts/ApprovalsReplacementCostsExportData'
import ApprovalsReplacementCostsImportData from '../containers/approvalsreplacementcosts/ApprovalsReplacementCostsImportData'

/**
 * Renders Approvals and Replacement Costs process flow steps and maintenance history.
 * @returns 
 */
export default function ApprovalsReplacementCosts() {

  const { approvals } = useProcessStatus()
  const { currentStatus, previousStatus } = approvals
  const { activeStep } = currentStatus
  const [ visibleStep, setVisibleStep ] = useState(currentStatus.activeStep.step || 'calculation')
  const [ percentage, setPercentage ] = useState('10%')
  const [ showSuccess, setShowSuccess ] = useState(false)
  const [ successStart, setSuccessStart ] = useState(null)
  const [ successEnd, setSuccessEnd ] = useState(null)

  useEffect(() => {
    const { step } = activeStep
    switch (step) {
      case 'exportCsv':
        setPercentage('30%')
        break
      case 'importCsv':
        setPercentage('60%')
        break
      case 'pushTrilogie':
        setPercentage('90%')
        break
      default:
        setPercentage('10%')
        break
    }
  }, [activeStep])

  useEffect(() => {
    const { step } = activeStep
    if (step) setVisibleStep(step)
  }, [activeStep])

  const onSkipOverridesReplayHandler = useCallback(() => {
    setVisibleStep('pushTrilogie')
  }, [])

  const onPushTrilogieCompletion = useCallback((startDate, endDate) => {
    setSuccessStart(startDate)
    setSuccessEnd(endDate)
    setShowSuccess(true) 
    setVisibleStep('calculation')
  }, [])

  return (
    <div>
      <Helmet>
        <title>Landed Cost - Approvals and Replacement Costs</title>
      </Helmet>
      <h1>Approvals and Replacement Costs</h1>
      <section id='approvals-and-replacement-costs'>
        <AutomationToggle />
        <ProgressBar steps={[
          { 
            stepText: 'calculation', 
            onClick: () => setVisibleStep('calculation'), 
            complete: true 
          },
          { 
            stepText: 'exportCsv', 
            onClick: () => setVisibleStep('exportCsv'), 
            complete: currentStatus.stepHistory.find(step => step.step === 'exportCsv') 
          },
          { 
            stepText: 'importCsv', 
            onClick: () => setVisibleStep('importCsv'), 
            complete: currentStatus.stepHistory.find(step => step.step === 'importCsv') 
          },
          { 
            stepText: 'pushTrilogie', 
            onClick: () => setVisibleStep('pushTrilogie'), 
            complete: currentStatus.stepHistory.find(step => step.step === 'pushTrilogie') 
          }
        ]} percentage={percentage} />
        <div className='process-flow-container'>
          {
            visibleStep === 'calculation' && 
              <ApprovalsReplacementCostsCalculation
                isVisible={visibleStep === 'calculation'} />
          }
          {
            visibleStep === 'exportCsv' && 
              <ApprovalsReplacementCostsExportData
                isVisible={visibleStep === 'exportCsv'} />
          }
          {
            visibleStep === 'importCsv' && 
              <ApprovalsReplacementCostsImportData
                replayHandler={onSkipOverridesReplayHandler}
                isVisible={visibleStep === 'importCsv'} />
          }
          {
            visibleStep === 'pushTrilogie' && 
              <ApprovalsReplacementCostsPushTrilogie 
                isVisible={visibleStep === 'pushTrilogie'}
                completionHandler={(startDate, endDate) => onPushTrilogieCompletion(startDate, endDate)} />
          }
        </div>
        <MaintenanceHistoryContainer 
          MaintenanceHistoryComponent={ApprovalsReplacementCostMaintenanceHistory} 
          previousStatus={previousStatus} 
          currentStatus={currentStatus} />
        <Modal 
          show={showSuccess} 
          onClose={() => setShowSuccess(false)} 
          type='success'>
          <div>
            <h1 style={{ textAlign: 'center' }}>Replacement Costs Sent to Trilogie PROD_SHARE</h1>
            <span style={{ textAlign: 'center', fontSize: 'x-large' }}>Calculation dates {successStart} - {successEnd}</span>
          </div>
        </Modal>
      </section>
    </div>
  )
}
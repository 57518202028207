import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'

/**
 * Renders a search bar with a magnifying glass. Executes a function on input.
 * @param {Object} props
 * @param {string} props.type Type of search.
 * @param {Function} props.handler Input change function.
 * @returns 
 */
export default function Search({ type, handler }) {
  return (
    <div className='search-field'>
      <Icon path={mdiMagnify} size={1} />
      <input type='text' placeholder={ type } onInput={handler}></input>
    </div>
  )
}
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { createPortal } from "react-dom";

export default function Modal({ show, onClose, onConfirm, type, headerText, children }) {

  return (
    <>
      {
        show && createPortal(
          <>
            <div onClick={onClose} className='modal-backdrop'></div>
            <div className='modal'>
              <div className={`modal-dialog ${type === 'success' ? 'modal-success' : ''}`}>
                <div className='modal-header'>
                  { headerText && <h3>{ headerText }</h3> }
                  {
                    type === 'success' && children
                  }
                  <Icon onClick={onClose} path={mdiClose} size={1} />
                </div>
                {
                  type !== 'success' ?
                    <div className='modal-body'>
                      {  children }
                    </div> :
                    <></>
                }

                <div className='modal-footer'>
                  { 
                    type === 'confirm' ? 
                      <>
                        <button onClick={onClose}>No</button>
                        <button onClick={onConfirm}>Yes</button>
                      </> :
                      <></>
                  }
                </div>
              </div>
            </div>
          </>, document.body)
      }
    </>
  )
}
import moment from 'moment'
import { useMemo } from 'react'

/**
 * Renders a table row based on the provided maintenance step.
 * @param {Object} props
 * @param {MaintenanceStep} props.maintenanceStep Step to render.
 * @returns 
 */
export default function MaintenanceHistoryLine({ maintenanceStep }) {
  const { stepText, stepTime, stepUser } = maintenanceStep

  const isDatetime = useMemo(() => moment(stepTime, true).isValid(), [stepTime])

  return (
    <tr>
      <td>{ stepText }:</td>
      <td>{ isDatetime ? moment(stepTime).format('M/D/YYYY, h:mm:ss A') : stepTime }</td>
      <td>{ stepUser }</td>
    </tr>
  )
}
import { Helmet } from 'react-helmet'
import OverviewSection from '../components/overview/OverviewSection'
import Icon from '@mdi/react'
import { mdiFerry, mdiCurrencyUsd, mdiMapOutline, mdiPercent, mdiCheckCircleOutline, mdiAlertCircleOutline } from '@mdi/js'
import { useVendorData } from '../contexts/VendorData'
import { useProcessStatus, useProcessStatusDispatch } from '../contexts/ProcessStatus'
import { useCallback, useEffect, useMemo, useState } from 'react'
import StaticStatusMessage from '../components/StaticStatusMessage'
import LiveProcessStatusIndicator from '../components/LiveProcessStatusIndicator'
import OverviewSectionProcess from '../components/overview/OverviewSectionProcess'
import StatusSpinner from '../components/StatusSpinner'
import moment from 'moment'
import ProcessButton from '../components/process/ProcessButton'
import { useMsal } from '@azure/msal-react'
import { post } from '../lib/api'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket'


/**
 * Renders links and statuses for each piece of the Landed Cost solution.
 * @returns 
 */
export default function Overview() {

  const { unmappedVendors } = useVendorData()
  const { vendorMapping, estimatedImportCosts, freightChargeGaps, approvals, dataMaintenance } = useProcessStatus()
  const dispatch = useProcessStatusDispatch()
  const { instance, accounts } = useMsal()

  const estimatedImportCostsStatus = useMemo(() => getStatusDetails(estimatedImportCosts), [estimatedImportCosts])
  const freightChargeGapsStatus = useMemo(() => getStatusDetails(freightChargeGaps), [freightChargeGaps])
  const approvalsStatus = useMemo(() => getStatusDetails(approvals), [approvals])

  const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, { share: true })
  const [ showRefreshResult, setShowRefreshResult ] = useState(false)
  const [ refreshResult, setRefreshResult ] = useState(false)

  const refreshIsDisabled = useMemo(() => {
    return estimatedImportCostsStatus.isLoading || 
      freightChargeGapsStatus.isLoading ||
      approvalsStatus.isLoading
  }, [estimatedImportCostsStatus, freightChargeGapsStatus, approvalsStatus])

  const vendorStatusMessage = useMemo(() => {
    return unmappedVendors.length > 0 ?
      `${unmappedVendors.length} unmapped vendors` :
      'All vendors mapped'
  }, [unmappedVendors])

  const onRefreshClick = useCallback(async () => {
    dispatch({
      type: 'UPDATE_DATA_REFRESH_STATUS',
      payload: true
    })
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    await post('data-maintenance', idToken, '{}')
  }, [dispatch, accounts, instance])

  useEffect(() => {
    if (lastJsonMessage?.orchestrationFinishedUpdate?.process === 'dataMaintenance') {
      setRefreshResult(lastJsonMessage.orchestrationFinishedUpdate.currentStatus.success)
      setShowRefreshResult(true)
    }
  }, [lastJsonMessage])

  return (
    <div>
      <Helmet>
        <title>Landed Cost - Overview</title>
      </Helmet>
      <h1>Landed Cost Overview</h1>
      <section>
        <OverviewSection title='From Freight Charge Gaps to Replacement Costs'>
          <OverviewSectionProcess 
            title='Freight Charge Gaps'
            link='freight-charge-gaps'
            icon={<Icon path={mdiFerry} size={6} />}>
              <LiveProcessStatusIndicator 
                type='overview' 
                isLoading={freightChargeGapsStatus.isLoading} 
                errors={freightChargeGapsStatus.errors} 
                statusText={freightChargeGapsStatus.statusText} />
                <br/>
                {
                  freightChargeGapsStatus.lastStep ?
                    <StaticStatusMessage
                      type='overview'>
                      <p>
                        { freightChargeGapsStatus.lastStep.step === 'exportCsv' ? 'Export ' : 'Import ' }
                        last completed by { freightChargeGapsStatus.lastStep.end?.user } at {' '}
                        { new Date(freightChargeGapsStatus.lastStep.end?.time).toLocaleString() }
                        <p>
                          { freightChargeGapsStatus.lastStep.file }
                        </p>
                      </p>
                    </StaticStatusMessage> : <></>
                }
          </OverviewSectionProcess>
          <OverviewSectionProcess 
            title='Approvals and Replacement Costs'
            link='approvals-replacement-costs'
            icon={<Icon path={mdiCurrencyUsd} size={6} />}>
              <LiveProcessStatusIndicator 
                type='overview' 
                isLoading={approvalsStatus.isLoading} 
                errors={approvalsStatus.errors} 
                statusText={approvalsStatus.statusText} />
                <br/>
                {
                  approvalsStatus.lastStep ?
                    <StaticStatusMessage
                      type='overview'>
                      <p>
                        { approvalsStatus.lastStep.step === 'calculation' ? 'Calculations last completed by ' : 'Trilogie file last created by ' } 
                        { approvalsStatus.lastStep.end?.user } at {' '}
                        { new Date(approvalsStatus.lastStep.end?.time).toLocaleString() } {' '}
                        for calculation range { `${moment(approvalsStatus.dates?.start).format('MM/DD/YYYY')} - ${moment(approvalsStatus.dates?.end).format('MM/DD/YYYY')}` }
                      </p>
                    </StaticStatusMessage> : <></>
                }
          </OverviewSectionProcess>
        </OverviewSection>
        <OverviewSection title='Data Maintenance'>
          <OverviewSectionProcess 
            title='Vendor Mapping'
            link='vendor-mapping'
            icon={<Icon path={mdiMapOutline} size={6} />}>
            {
              vendorMapping.isLoading ?
                <StatusSpinner /> :
                <StaticStatusMessage statusText={vendorStatusMessage} />
            }
          </OverviewSectionProcess>
          <OverviewSectionProcess 
            title='Estimated Import Costs'
            link='estimated-import-costs'
            icon={<Icon path={mdiPercent} size={6} />}>
              <LiveProcessStatusIndicator 
                type='overview' 
                isLoading={estimatedImportCostsStatus.isLoading} 
                errors={estimatedImportCostsStatus.errors} 
                statusText={estimatedImportCostsStatus.statusText} />
                <br/>
                {
                  estimatedImportCostsStatus.lastStep ?
                    <StaticStatusMessage
                      type='overview'>
                      <p>
                        { estimatedImportCostsStatus.lastProcessType === 'product' ? 'Product/Whse ' : 'Linebuy/Whse '}
                        { estimatedImportCostsStatus.lastStep.step === 'exportCsv' ? 'export' : 'import' } last completed by {' '}
                        { estimatedImportCostsStatus.lastStep.end?.user } at {' '}
                        { new Date(estimatedImportCostsStatus.lastStep.end?.time).toLocaleString() }
                        <p>
                          { estimatedImportCostsStatus.lastStep.file }
                        </p>
                      </p>
                    </StaticStatusMessage> : <></>
                }
          </OverviewSectionProcess>
          <div className='process-button-overview-container'>
            {
              dataMaintenance.isLoading ?
                <div>
                  <span>
                    <StatusSpinner />
                    Refresh in progress...
                  </span>
                </div> :
                <div>
                  <ProcessButton 
                    buttonText='Refresh Data'
                    className='process-button-overview'
                    isDisabled={refreshIsDisabled}
                    handler={onRefreshClick} />
                    {
                      showRefreshResult &&
                      <span
                        style={{color: refreshResult ? '#00446a' : '#cc2f2f'}}>
                        <Icon path={refreshResult ? mdiCheckCircleOutline : mdiAlertCircleOutline} size={1}/>
                        Refresh {refreshResult ? ' successful' : ' failed'}
                      </span>
                    }
                </div>

            }
          </div>
        </OverviewSection>
      </section>
    </div>
  )
}

function getStatusDetails (processStatus) {
  const { currentStatus, previousStatus, statusText } = processStatus
  const { start, end, errors } = currentStatus.activeStep || {}
  const isInProgress = currentStatus.stepHistory[currentStatus.stepHistory.length - 1]
  return {
    isLoading: start && !end && !errors,
    statusText: statusText,
    errors: errors,
    dates: isInProgress ? currentStatus.dates : previousStatus.dates,
    lastStep: currentStatus.stepHistory[currentStatus.stepHistory.length - 1] || 
      previousStatus.stepHistory[previousStatus.stepHistory.length - 1] || undefined,
    lastProcessType: currentStatus.processType || previousStatus.processType || undefined
  }
}
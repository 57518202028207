import { useMemo } from "react";
import MaintenanceHistory from "./MaintenanceHistory";

export default function FreightChargeGapsMaintenanceHistory({ version, status }) {

  const { activeStep, stepHistory } = status
  const steps = useMemo(() => [activeStep, ...stepHistory], [activeStep, stepHistory])
  const exportStep = useMemo(() => steps.find(step => step.step === 'exportCsv'), [steps])
  const importStep = useMemo(() => steps.find(step => step.step === 'importCsv'), [steps])

  const maintenanceSteps = [
    { stepText: 'Export CSV Started', stepTime: exportStep?.start?.time || '', stepUser: exportStep?.start?.user || '' },
    { stepText: 'Export CSV Completed', stepTime: exportStep?.end?.time || '', stepUser: exportStep?.end?.user || '' },
    { stepText: 'Import CSV Started', stepTime: importStep?.start?.time || '', stepUser: importStep?.start?.user || '' },
    { stepText: 'Import CSV Completed', stepTime: importStep?.end?.time || '', stepUser: importStep?.end?.user || '' },
  ]

  return (
    <MaintenanceHistory version={version} maintenanceSteps={maintenanceSteps} />
  )
}
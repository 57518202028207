import { mdiCalendarMonthOutline } from "@mdi/js";
import Icon from "@mdi/react";
import DatePicker from 'react-datepicker'

/**
 * Renders a datepicker with the provided label and change handler function.
 * @param {Object} props
 * @param {string} props.labelText Text to render next to datepicker.
 * @param {Function} props.handler Change handler function.
 * @returns 
 */
export default function ProcessDatepicker({ labelText, date, handler, startDate, endDate, minDate, selectsStart, selectsEnd }) {
  return (
    <div className="process-datepicker">
      <span className='process-datepicker-label'>{ labelText }</span>
      <DatePicker
        id={`process-datepicker-input-${labelText.toLowerCase().replaceAll(' ', '-')}`}
        calendarClassName='process-datepicker-calendar'
        shouldCloseOnSelect
        strictParsing
        minDate={minDate}
        startDate={startDate}
        endDate={endDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        selected={date}
        onChange={handler} />
      <label for={`process-datepicker-input-${labelText.toLowerCase().replaceAll(' ', '-')}`}>
        <Icon path={mdiCalendarMonthOutline} size={1} />
      </label>
    </div>
  )
}
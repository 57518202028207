import { useCallback, useMemo, useState } from "react";
import { useVendorData } from "../../contexts/VendorData";
import Search from "./Search";
import DataGrid from "../datagrid/DataGrid";
import uFuzzy from '@leeoniya/ufuzzy'

/**
 * Renders a title, Search component and DataGrid component for a type of Vendor List.
 * Relies on VendorData context.
 * @param {Object} props
 * @param {string} props.type Type of vendor list.
 * @returns 
 */
export default function VendorList({ type }) {
  const { mappedVendors, unmappedVendors } = useVendorData()
  const records = useMemo(() => type === 'Unmapped Vendors' ? unmappedVendors : mappedVendors, [unmappedVendors, mappedVendors, type])
  const [ searchValue, setSearchValue ] = useState('')

  const onSearchInput = useCallback((e) => setSearchValue(e.target.value), [])
  const maxHeight = useMemo(() => unmappedVendors.length === 0 ? '700px' : '300px', [unmappedVendors])

  return (
    <div data-type={type} className='vendor-list'>
      <h3>{ type } ({ records.length })</h3>
      <Search type={type.toLowerCase().concat(' search')} handler={onSearchInput} />
      <DataGrid records={filterRecords(records, searchValue)} maxHeight={maxHeight} />
    </div>
  )
}

function filterRecords(records, searchText) {
  if (searchText === '') return records
  const fuzzy = new uFuzzy({
    intraMode: 1,
    intraSub: 1,
    intraTrn: 1,
    intraDel: 1,
    intraIns: 5
  })
  return records.filter(record => fuzzy.filter(Object.values(record), searchText)?.length > 0)
}
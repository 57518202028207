import { useCallback, useMemo, useState } from "react";
import ProcessFlowStep from "../../components/process/ProcessFlowStep";
import { useProcessStatus, useProcessStatusDispatch } from "../../contexts/ProcessStatus";
import ProcessButton from "../../components/process/ProcessButton";
import StaticStatusMessage from "../../components/StaticStatusMessage";
import LiveProcessStatusIndicator from "../../components/LiveProcessStatusIndicator";
import moment from "moment";
import { get } from "../../lib/api";
import { useMsal } from "@azure/msal-react";

export default function ApprovalsReplacementCostsExportData({ isVisible }) {

  const { instance, accounts } = useMsal()
  const { approvals, dataMaintenance } = useProcessStatus()
  const dispatch = useProcessStatusDispatch()
  const { currentStatus, statusText } = approvals
  const { activeStep, stepHistory, dates } = currentStatus
  const { step, start, end, errors } = activeStep
  
  const [ isLoading, setIsLoading ] = useState(false)
  const [ tempStatus, setTempStatus ] = useState(null)

  const isComplete = useMemo(() => stepHistory.find(step => step.step === 'exportCsv'), [stepHistory])

  const onFileDownload = useCallback(async () => {
    setIsLoading(true)
    setTempStatus('Export in progress...')

    if (!isComplete) {
      const { errors, errorCount, ...activeStepData } = activeStep
      if (!activeStepData.hasOwnProperty('start')) activeStepData.start = {}
      dispatch({
        type: 'UPDATE_CURRENT_STATUS',
        payload: { 
          process: 'approvals', 
          status: { ...currentStatus, activeStep: activeStepData }
        }})
    }

    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await get(`approvals-replacement-costs/download?replay=${!!isComplete}&start=${moment().toISOString()}`, idToken)
    if (response.ok) {
      const fileData = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(fileData)
      downloadLink.download = response.headers.get('file')
      downloadLink.click()
    }
    setIsLoading(false)
  }, [instance, accounts, dispatch, activeStep, currentStatus, isComplete])

  return (
    <ProcessFlowStep
      stepText='2. Export Calculated Replacement Costs'
      hidden={!isVisible}
      isActive={step === 'exportCsv'}
      isHistorical={isComplete}>
      { 
        isComplete && 
        <StaticStatusMessage>
          { `Export last completed by ${isComplete.end.user}`}
          <br />
          { new Date(isComplete.end.time).toLocaleString() }
          <br />
          for { `${moment(dates?.start).format('MM/DD/YYYY')} - ${moment(dates?.end).format('MM/DD/YYYY')}` }
          <br />
          { isComplete.file }
        </StaticStatusMessage>
      }
      <ProcessButton 
        buttonText='Export Data' 
        handler={onFileDownload} 
        isDisabled={dataMaintenance.isLoading || isLoading || (start && !end && !errors)} />
      { 
        (!isComplete || isLoading) && 
        <LiveProcessStatusIndicator 
          type='process' 
          isLoading={isLoading || (start && !end && !errors)} 
          errors={isLoading ? [] : errors}
          statusText={tempStatus || statusText} /> 
      }
    </ProcessFlowStep>
  )
}
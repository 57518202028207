import { useMemo } from "react";
import MaintenanceHistory from "./MaintenanceHistory";
import moment from 'moment'

export default function ApprovalsReplacementCostMaintenanceHistory({ version, status }) {

  const { activeStep, stepHistory, dates } = status
  const steps = useMemo(() => [activeStep, ...stepHistory], [activeStep, stepHistory])
  const calculationStep = useMemo(() => steps.find(step => step.step === 'calculation'), [steps])
  const dateText = `${moment(dates?.start).format('MM/DD/YYYY')} - ${moment(dates.end).format('MM/DD/YYYY')}`
  const exportStep = useMemo(() => steps.find(step => step.step === 'exportCsv'), [steps])
  const importStep = useMemo(() => steps.find(step => step.step === 'importCsv'), [steps])
  const pushTrilogieStep = useMemo(() => steps.find(step => step.step === 'pushTrilogie'), [steps])

  const maintenanceSteps = [
    { stepText: 'Calculation Dates', stepTime: dates ? dateText : '' },
    { stepText: 'Calculation Started', stepTime: calculationStep?.start?.time || '', stepUser: calculationStep?.start?.user || '' },
    { stepText: 'Calculation Completed', stepTime: calculationStep?.end?.time || '', stepUser: calculationStep?.end?.user || '' },
    { stepText: 'Export CSV Started', stepTime: exportStep?.start?.time || '', stepUser: exportStep?.start?.user || '' },
    { stepText: 'Export CSV Completed', stepTime: exportStep?.end?.time || '', stepUser: exportStep?.end?.user || '' },
    { stepText: 'Import CSV Started', stepTime: importStep?.skipped ? 'Skipped' : importStep?.start?.time || '', stepUser: importStep?.start?.user || '' },
    { stepText: 'Import CSV Completed', stepTime: importStep?.skipped ? 'Skipped' : importStep?.end?.time || '', stepUser: importStep?.end?.user || '' },
    { stepText: 'Push to Trilogie Started', stepTime: pushTrilogieStep?.start?.time || '', stepUser: pushTrilogieStep?.start?.user || '' },
    { stepText: 'Push to Trilogie Completed', stepTime: pushTrilogieStep?.end?.time || '', stepUser: pushTrilogieStep?.end?.user || '' },
  ]

  return (
    <MaintenanceHistory version={version} maintenanceSteps={maintenanceSteps} />
  )
}
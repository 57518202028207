import { Helmet } from 'react-helmet'
import VendorList from '../components/vendormapping/VendorList'
import { useVendorData } from '../contexts/VendorData'
import { useProcessStatus } from '../contexts/ProcessStatus'
import Spinner from '../components/Spinner'

/**
 * Renders vendor mapping tables.
 * Relies on vendor data context.
 * @returns 
 */
export default function VendorMapping() {
  const { vendorMapping } = useProcessStatus()
  const { unmappedVendors } = useVendorData()

  return (
    <div>
      <Helmet>
        <title>Landed Cost - Vendor Mapping</title>
      </Helmet>
      <h1>Vendor Mapping</h1>
      {
        vendorMapping.isLoading ?
          <Spinner height={128} width={128} display='block' margin='0 auto' /> :
          <section id='vendor-mapping'>
            { unmappedVendors.length > 0 && <VendorList type='Unmapped Vendors' /> }
            <VendorList type='Mapped Vendors' />
          </section>
      }
    </div>
  )
}
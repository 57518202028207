import { Link } from "react-router-dom"

/**
 * Renders a box with a configured section process.
 * @param {Object} props
 * @param {SectionProcess} props.sectionProcess Section process to render. 
 * @returns 
 */
export default function OverviewSectionProcess({ title, link, icon, children }) {
  return (
    <Link to={ link }>
      <div className="overview-section-process">
        { icon }
        <h3>{ title }</h3>
        { children }
      </div>
    </Link>
  )
}
/**
 * Renders a box with the configured process flow step text and any children.
 * @param {Object} props
 * @param {string} props.stepText Title of process flow step.
 * @param {boolean} props.isActive Whether or not the step is active.
 * @param {any} props.children Children to render.
 * @returns 
 */
export default function ProcessFlowStep({ stepText, isActive, isHistorical, children, hidden }) {
  return (
    <div 
      style={{
        display: hidden ? 'none' : ''
      }}
      className={`process-flow-step ${isActive ? 'active' : ''}`}>
      <h3>{ stepText }</h3>
      {
        isActive || isHistorical ?
          children :
          <p>Please complete the previous step.</p>
      }
    </div>
  )
}
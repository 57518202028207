import Icon from "@mdi/react"
import { mdiPencilOutline } from "@mdi/js"
import { useCallback, useState, useMemo } from "react"
import { useVendorData, useVendorDataDispatch } from "../../contexts/VendorData"
import { useMsal } from "@azure/msal-react"
import { patch, post } from "../../lib/api"
import Spinner from "../Spinner"

/**
 * Renders a table data cell.
 * Relies on VendorData dispatch.
 * @param {Object} props 
 * @param {string} props.type 'string' or 'date', affects formatting.
 * @param {any} props.content Cell content.
 * @param {boolean} props.isEditable Renders a select inside table cell when true, otherwise just renders content.
 * @param {string} props.recordKey Used for editable cells, reference for record in memory.
 * @returns 
 */
export default function DataGridCell({ type = 'string', content, isEditable, recordKey }) {
  const [ isEditEnabled, setIsEditEnabled ] = useState(isEditable && !content)
  const [ value, setValue ] = useState(content || '')
  const [ isLoading, setIsLoading ] = useState(false)
  const { mappedVendors, unmappedVendors } = useVendorData()
  const vendorDataDispatch = useVendorDataDispatch()
  const { accounts, instance } = useMsal()

  const chargeTypes = useMemo(() => ({
    BROKERAGE: 'Brokerage',
    OCEAN: 'Ocean',
    DRAYAGE: 'Drayage',
    ADMIN: 'Admin',
    TARIFF: 'Tariff and Anti-Dumping Fees',
    DEMURRAGE: 'Demurrage and Detention Charges'
  }), [])

  // Toggle editing on editable cells.
  const onClick = useCallback(() => {
    if (!isEditable) return
    if (isEditable && isEditEnabled) return
    setIsEditEnabled(!isEditEnabled)    
  }, [isEditable, isEditEnabled])

  // Dispatch update to memory.
  // TODO: Convert alerts to actual error messages.
  // TODO: Refactor.
  const onChange = useCallback(async e => {
    setValue(e.target.value)
    setIsLoading(true)

    const vendorIsMapped = e.target.closest('.vendor-list').dataset.type === 'Mapped Vendors'
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    if (vendorIsMapped) {
      const vendor = mappedVendors.find(vendor => vendor.id === e.target.dataset.key)
      const response = await patch(`vendors/${vendor.id}`, idToken, JSON.stringify({
        LOGON: vendor.LOGON,
        VENDOR_NUMBER: vendor.VENDOR_NUMBER,
        VENDOR_NAME: vendor.VENDOR_NAME,
        CHARGE_TYPE: e.target.value,
        UPDATED_BY: accounts[0].name,
        UPDATED_DATE: new Date().toISOString()
      }))

      if (response.status === 200) {
        const json = await response.json()
        vendorDataDispatch({ type: 'SET_CHARGE_TYPE', payload: {
          id: e.target.dataset.key,
          ...json
        }})
      } else {
        alert('unable to update vendor')
      }

    } else {
      const vendor = unmappedVendors.find(vendor => vendor.id === e.target.dataset.key)
      const response = await post('vendors', idToken, JSON.stringify({
        LOGON: vendor.LOGON,
        VENDOR_NUMBER: vendor.VENDOR_NUMBER,
        VENDOR_NAME: vendor.VENDOR_NAME,
        CHARGE_TYPE: e.target.value,
        CREATED_BY: accounts[0].name,
        CREATED_DATE: new Date().toISOString()
      }))

      if (response.status === 201) {
        const json = await response.json()
        vendorDataDispatch({ type: 'SET_CHARGE_TYPE', payload: {
          id: e.target.dataset.key,
          ...json
        }})
      } else {
        alert('unable to save vendor')
      }

    }

    setIsLoading(false)
    setIsEditEnabled(false)
    setTimeout(() => {
      const row = document.getElementById(e.target.dataset.key)
      if (row) {
        row.classList.add('highlight-row')
        setTimeout(() => row.classList.remove('highlight-row'), 1500)
      }
    }, 200)
  }, [vendorDataDispatch, accounts, instance, mappedVendors, unmappedVendors])

  // Format content appropriately based on data type.
  const formattedContent = useMemo(() => {
    if (type === 'string') {
      return content
    } else if (type === 'date') {
      return content !== null ?
        new Date(content).toLocaleString() :
        content
    } else if (type === 'select') {
      return chargeTypes[content]
    }
  }, [type, content, chargeTypes])

  return (
    <td className={`data-grid-cell ${isEditable ? 'data-grid-editable-cell' : ''}`} onClick={onClick}>
      { 
        isEditEnabled ?
          <select value={value} data-key={recordKey} onChange={onChange}>
            <option value='' disabled>-- Select charge type --</option>
            <option value='BROKERAGE'>Brokerage</option>
            <option value='OCEAN'>Ocean</option>
            <option value='DRAYAGE'>Drayage</option>
            <option value='ADMIN'>Admin</option>
            <option value='TARIFF'>Tariff and Anti-Dumping Fees</option>
            <option value='DEMURRAGE'>Demurrage and Detention Charges</option>
          </select> :
          formattedContent
      }
      { isEditable && !isEditEnabled && <Icon path={mdiPencilOutline} size={0.75} /> }
      { isLoading && <div className="data-grid-cell-loading"><Spinner /></div>}
    </td>
  )
}
import { Helmet } from 'react-helmet'
import AutomationToggle from '../components/AutomationToggle'
import ProgressBar from '../components/progress/ProgressBar'
import { useProcessStatus } from '../contexts/ProcessStatus'
import { useState, useEffect } from 'react'
import Modal from '../containers/Modal'
import MaintenanceHistoryContainer from '../containers/MaintenanceHistoryContainer'
import FreightChargeGapsMaintenanceHistory from '../components/maintenance/FreightChargeGapsMaintenanceHistory'
import FreightChargeGapsExportData from '../containers/freightchargegaps/FreightChargeGapsExportData'
import FreightChargeGapsImportData from '../containers/freightchargegaps/FreightChargeGapsImportData'

/**
 * Renders Freight Charge Gaps process flow steps and maintenance history.
 * @returns 
 */
export default function FreightChargeGaps() {

  const { freightChargeGaps } = useProcessStatus()
  const { currentStatus, previousStatus } = freightChargeGaps

  const [ visibleStep, setVisibleStep ] = useState(currentStatus.activeStep.step || 'exportCsv')
  const [ percentage, setPercentage ] = useState('10%')
  const [ showSuccess, setShowSuccess ] = useState(false)

  useEffect(() => {
    const { activeStep } = currentStatus
    activeStep.step === 'importCsv' ?
      setPercentage('90%') :
      setPercentage('10%')
  }, [currentStatus])

  useEffect(() => {
    const { activeStep } = currentStatus
    const { step } = activeStep
    if (step) setVisibleStep(step)
  }, [currentStatus])

  return (
    <div>
      <Helmet>
        <title>Landed Cost - Freight Charge Gaps</title>
      </Helmet>
      <h1>Freight Charge Gaps</h1>
      <section id='freight-charge-gaps'>
        <AutomationToggle />
        <ProgressBar steps={[
          { stepText: 'exportCsv', onClick: () => setVisibleStep('exportCsv'), complete: true },
          { stepText: 'importCsv', onClick: () => setVisibleStep('importCsv'), complete: currentStatus.stepHistory.find(step => step.step === 'importCsv') }
        ]} percentage={percentage} />
        <div className='process-flow-container'>
          {
            visibleStep === 'exportCsv' ?
              <FreightChargeGapsExportData /> :
              <FreightChargeGapsImportData completionHandler={() => {setShowSuccess(true); setVisibleStep('exportCsv')}} />
          }
        </div>
        <MaintenanceHistoryContainer MaintenanceHistoryComponent={FreightChargeGapsMaintenanceHistory} previousStatus={previousStatus} currentStatus={currentStatus} />
        <Modal show={showSuccess} onClose={() => setShowSuccess(false)} type='success'>
          <h1 style={{ textAlign:'center'}}>Import Successful</h1>
        </Modal>
      </section>
    </div>
  )
}
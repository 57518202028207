import HorizontalRule from "../components/HorizontalRule"

export default function MaintenanceHistoryContainer({ MaintenanceHistoryComponent, previousStatus, currentStatus }) {
  return (
    <div>
      {
        previousStatus.stepHistory.length !== 0 ||
        currentStatus.stepHistory.length !== 0 ?
          <div>
            <HorizontalRule />
            <div className="maintenance-history-container">
              {
                currentStatus.stepHistory.length !== 0 &&
                <MaintenanceHistoryComponent version='Current Maintenance History' status={currentStatus}></MaintenanceHistoryComponent>
              }
              {
                previousStatus.stepHistory.length !== 0 &&
                <MaintenanceHistoryComponent version='Previous Maintenance History' status={previousStatus}></MaintenanceHistoryComponent>
              }
            </div>
          </div> : <></>
      }
    </div>
  )
}
import { useCallback, useState } from "react"
import ProcessButton from "./ProcessButton"
import Modal from "../../containers/Modal"

/**
 * Renders a filepicker with the provided label and change handler function.
 * @param {Object} props
 * @param {string} props.labelText Text to render next to filepicker.
 * @param {Function} props.handler Change handler function.
 * @returns 
 */
export default function ProcessFilepicker({ fileName, cancelHandler, changeHandler, uploadHandler, isDisabled }) {

  const [ showNotification, setShowNotification ] = useState(false)
  
  const onChange = useCallback(e => {
    if (e.target.files.length === 0) return
    if (e.target.files[0]?.type === 'text/csv') {
      changeHandler(e)
    } else {
      setShowNotification(true)
    }
  }, [changeHandler])


  return (
    <>
      <div className='process-filepicker'>
        <div className='process-filepicker-name'>{ fileName }</div>
        <label>
          Browse
          <input type='file' onChange={onChange} hidden accept=".csv"/>
        </label>
      </div>
      <div className='process-filepicker-buttons'>
        <ProcessButton buttonText="Cancel" handler={cancelHandler} isDisabled={isDisabled || fileName === null} />
        <ProcessButton buttonText="Import Data" handler={uploadHandler} isDisabled={isDisabled || fileName === null} />
      </div>
      <Modal type='error' show={showNotification} onClose={() => setShowNotification(false)} headerText={'Invalid File Type'}>
        <p>File type must be .CSV</p>
      </Modal>
    </>
  )
}
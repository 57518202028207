import { useCallback, useMemo, useState, useEffect } from "react"
import { useProcessStatus, useProcessStatusDispatch } from "../../contexts/ProcessStatus"
import ProcessFlowStep from "../../components/process/ProcessFlowStep"
import ProcessButton from "../../components/process/ProcessButton"
import LiveProcessStatusIndicator from "../../components/LiveProcessStatusIndicator"
import StaticStatusMessage from "../../components/StaticStatusMessage"
import moment from "moment"
import { useMsal } from "@azure/msal-react"
import { patch } from "../../lib/api"
import Modal from "../Modal"

export default function ApprovalsReplacementCostsPushTrilogie({ isVisible, completionHandler }) {
  
  const { instance, accounts } = useMsal()
  const { approvals, dataMaintenance } = useProcessStatus()
  const dispatch = useProcessStatusDispatch()
  const { currentStatus, statusText } = approvals
  const { activeStep, stepHistory, dates } = currentStatus
  const { step, start, end, errors } = activeStep

  const [ showConfirm, setShowConfirm ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ tempStatus, setTempStatus ] = useState(null)

  const isComplete = useMemo(() => stepHistory.find(step => step.step === 'pushTrilogie'), [stepHistory])
  const calculationStep = useMemo(() => stepHistory.find(step => step.step === 'calculation'), [stepHistory])

  useEffect(() => { 
    if (isComplete) {
      const startDate = moment(dates?.start).format('M/D/YYYY')
      const endDate = moment(dates?.end).format('M/D/YYYY')
      completionHandler(startDate, endDate) 
    }}, [isComplete, completionHandler, dates])

  const onConfirm = useCallback(async () => {
    setShowConfirm(false)
    setIsLoading(true)
    setTempStatus('Sending replacement cost file to Trilogie')
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    await patch(`approvals-replacement-costs/${currentStatus.id}`, idToken, JSON.stringify({
      APPROVED_AT: moment().toISOString()
    }))
    const { errors, errorCount, ...activeStepData } = activeStep
    if (!activeStepData.hasOwnProperty('start')) activeStepData.start = {}
    dispatch({
      type: 'UPDATE_CURRENT_STATUS',
      payload: { process: 'approvals', status: { ...currentStatus, activeStep: activeStepData }}
    })
    setIsLoading(false)
  }, [accounts, instance, currentStatus, activeStep, dispatch])

  return (
    <ProcessFlowStep
      stepText='4. Approve Calculations and Send Replacement Costs to Trilogie'
      hidden={!isVisible}
      isActive={step === 'pushTrilogie'}
      isHistorical={isComplete}>
      { 
        calculationStep ?
          <StaticStatusMessage>
            { calculationStep.step === 'calculation' ? 'Calculations last completed by' : 'Trilogie file last created by' } { calculationStep.end.user }
            <br />
            { new Date(calculationStep.end.time).toLocaleString() }
            <br />
            for { `${moment(dates?.start).format('MM/DD/YYYY')} - ${moment(dates?.end).format('MM/DD/YYYY')}` }
            <br />
            { calculationStep.file }
          </StaticStatusMessage> : <></>
      }
      <ProcessButton buttonText='Approve and Send to Trilogie' handler={() => setShowConfirm(true)} isDisabled={dataMaintenance.isLoading || isLoading || (start && !end && !errors)} />
      {
        (!isComplete || !isLoading) &&
        <LiveProcessStatusIndicator
          type='process'
          isLoading={isLoading || (start && !end && !errors)}
          errors={isLoading ? [] : errors}
          statusText={tempStatus || statusText} />
      }
      <Modal 
        show={showConfirm} 
        onClose={() => setShowConfirm(false)} 
        type='confirm' 
        onConfirm={onConfirm} 
        headerText='Confirm'>
        <span>This will send new replacement costs to Trilogie.<br />Are you sure wish to continue?</span>
      </Modal> 
    </ProcessFlowStep>
  )
}
import 'react-datepicker/dist/react-datepicker.css'
import './styles.css';
import { Route, redirect, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom' 
import useWebSocket from 'react-use-websocket'
import Overview from './pages/Overview';
import VendorMapping from './pages/VendorMapping';
import FreightChargeGaps from './pages/FreightChargeGaps';
import ApprovalsReplacementCosts from './pages/ApprovalsReplacementCosts';
import EstimatedImportCosts from './pages/EstimatedImportCosts';
import { VendorDataProvider } from './contexts/VendorData';
import { ProcessStatusProvider } from './contexts/ProcessStatus';
import { useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import Layout from './pages/Layout';
import NoAccess from './pages/NoAccess';

function App() {

  const { accounts } = useMsal()

  useWebSocket(process.env.REACT_APP_WS_URL, {
    share: true,
    filter: () => false,
    retryOnError: true,
    shouldReconnect: () => true
  })

  const isDataMaintainer = useMemo(() => accounts[0].idTokenClaims?.roles?.some(role => role === 'LandedCost.DataMaintainer'), [accounts])
  const isAssociate = useMemo(() => accounts[0].idTokenClaims?.roles?.some(role => role === 'LandedCost.Associate'), [accounts])
  const isApprover = useMemo(() => accounts[0].idTokenClaims?.roles?.some(role => role === 'LandedCost.Approver'), [accounts])
  const isPermitted = useMemo(() => isDataMaintainer || isAssociate || isApprover, [isDataMaintainer, isAssociate, isApprover])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='/' element={<Layout />}>
        <Route index element={<Overview />} />
        <Route loader={() => isAssociate ? null : redirect('/')} path='freight-charge-gaps' element={<FreightChargeGaps />} />
        <Route loader={() => isApprover ? null : redirect('/')} path='approvals-replacement-costs' element={<ApprovalsReplacementCosts />} />
        <Route loader={() => isDataMaintainer ? null : redirect('/')} path='vendor-mapping' element={<VendorMapping />} />
        <Route loader={() => isDataMaintainer ? null : redirect('/')} path='estimated-import-costs' element={<EstimatedImportCosts />} />
      </Route>
    )
  )

  return (
    <div className="App">
      {
        isPermitted ?
          <ProcessStatusProvider>
            <VendorDataProvider>
              <RouterProvider router={router} />
            </VendorDataProvider>
          </ProcessStatusProvider> :
          <NoAccess />
      }
    </div>
  );
}

export default App;

/**
 * Renders a button with the provided label and click handler function.
 * @param {Object} props
 * @param {string} props.buttonText Text to render within button.
 * @param {Function} props.handler Click handler function.
 * @returns 
 */
export default function ProcessButton({ buttonText, handler, property, isDisabled, className }) {
  return (
    <button disabled={isDisabled} className={`process-button ${className ? className : ''}`} data-property={property} onClick={isDisabled ? () => {} : handler}>
      { buttonText }
    </button>
  )
}
import { useCallback, useMemo, useState } from "react"
import { useMsal } from "@azure/msal-react"
import ProcessFlowStep from "../../components/process/ProcessFlowStep"
import ProcessButton from "../../components/process/ProcessButton"
import LiveProcessStatusIndicator from "../../components/LiveProcessStatusIndicator"
import { useProcessStatus } from "../../contexts/ProcessStatus"
import { get, sendDelete } from "../../lib/api"
import Modal from "../Modal"
import StaticStatusMessage from "../../components/StaticStatusMessage"

export default function EstimatedCostsExportData() {

  const { estimatedImportCosts, dataMaintenance } = useProcessStatus()
  const { currentStatus, statusText } = estimatedImportCosts
  const { activeStep, stepHistory } = currentStatus
  const { step, start, end, errors } = activeStep
  const { instance, accounts } = useMsal()

  const [ tempStatus, setTempStatus ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ restartProcessType, setRestartProcessType ] = useState(null)
  const [ showRestartNotification, setShowRestartNotification ] = useState(false)

  const isComplete = useMemo(() => stepHistory.find(step => step.step === 'exportCsv'), [stepHistory])

  const onFileDownload = useCallback(async (event) => {
    setIsLoading(true)
    setTempStatus('Export in progress...')
    
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const processType = restartProcessType || event.target.dataset.property
    const response = await get(`estimated-import-costs?processType=${processType}`, idToken)
    setRestartProcessType(null)
    if (response.ok) {
      const fileData = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(fileData)
      downloadLink.download = response.headers.get('file')
      downloadLink.click()
    }
    setIsLoading(false)
  }, [instance, accounts, restartProcessType])

  const onProcessRestart = useCallback(async (event) => {
    setRestartProcessType(event.target.dataset.property)
    setShowRestartNotification(true)
  }, [])

  const onClose = useCallback(e => {
    e.stopPropagation()
    setShowRestartNotification(false)
  }, [])

  const onRestartConfirm = useCallback(async (event) => {
    setIsLoading(true)
    setTempStatus('Restarting process...')
    setShowRestartNotification(false)
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await sendDelete(`estimated-import-costs/${currentStatus.id}`, idToken)
    if (response.ok) await onFileDownload(event)
  }, [accounts, currentStatus.id, instance, onFileDownload])

  return (
    <ProcessFlowStep 
      stepText='1. Export Estimated Cost Data' 
      isActive={step !== 'importCsv'} 
      isHistorical={isComplete}>
      { 
        isComplete && 
        <StaticStatusMessage>
          { isComplete.step === 'exportCsv' ? 'Export' : 'Import' } last completed by { isComplete.end.user }
          <br />
          { new Date(isComplete.end.time).toLocaleString() }
          <br />
          { isComplete.file }
        </StaticStatusMessage>
      }
      <div className='process-button-container'>
        <ProcessButton 
          buttonText='Export Linebuy/Whse Data'
          property='linebuy' 
          handler={isComplete ? onProcessRestart : onFileDownload} 
          isDisabled={dataMaintenance.isLoading || isLoading || (start && !end && !errors)} />
        <ProcessButton
          buttonText='Export Product/Whse Data'
          property='product'
          handler={isComplete ? onProcessRestart : onFileDownload}
          isDisabled={dataMaintenance.isLoading || isLoading || (start && !end && !errors)} />
      </div>
      { 
        (!isComplete || isLoading) && 
        <LiveProcessStatusIndicator 
          type='process' 
          isLoading={isLoading || (start && !end && !errors)} 
          errors={isLoading ? [] : errors}
          statusText={tempStatus || statusText} /> 
      }
      <Modal show={showRestartNotification} onClose={onClose} type='confirm' onConfirm={onRestartConfirm} headerText={'Warning'}>
        <span>This will restart the current process.<br />Do you wish to export the data again?</span>
      </Modal> 
  </ProcessFlowStep>

  )
}
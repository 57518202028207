export default function ProgressBar({ type, steps = [], percentage }) {  
  return (
    <div className='progress-bar'>
      <div className='progress-bar-steps'>
        {
          steps.map((step, idx) => <span 
            key={step.stepText} 
            className={`progress-bar-circle ${step.complete ? 'active' : ''}`} 
            onClick={step.onClick}>{ idx + 1 }</span>)
        }
        <div className='progress'>
          <span className='indicator' style={{
            width: percentage
          }}></span>
        </div>
      </div>
    </div>
  )
}
import Icon from "@mdi/react";
import StatusSpinner from "./StatusSpinner";
import { mdiAlertCircleOutline } from "@mdi/js";
import { useCallback, useState } from "react";
import Modal from "../containers/Modal";

/**
 * Renders a div with the provided status text.
 * @param {Object} props
 * @param {string} props.type Type of search.
 * @param {Function} props.statusText Input change function.
 * @returns 
 */
export default function LiveProcessStatusIndicator({ type, isLoading = false, errors = [], errorCount, statusText }) {

  const [ showError, setShowError ] = useState(false)

  const onClose = useCallback(e => {
    e.stopPropagation()
    setShowError(false)
  }, [])

  return (
    <div className={`status-indicator ${errors.length > 0 ? 'status-indicator-error' : ''}`}>
      {
        errors.length > 0 && type === 'process' ?
          <span onClick={() => setShowError(true)}>
            <Icon size={1} path={mdiAlertCircleOutline} />
            <span>
              { 
                errors.length === 1 ?
                  <>{ errors[0] }</> :
                  <>{ errorCount } errors encountered. <span style={{ textDecoration: 'underline' }}>View Details.</span></>
              }
            </span>
            <Modal show={showError} onClose={onClose} headerText={`${errorCount} error${errorCount > 1 ? 's' : ''}`}>
              { errorCount > 5 ? <>The first 5 errors are displayed below.<br /><br /></> : <></> }
              Please review and correct all related errors before importing again.
              <ul>
                { errors.map(error => <li key={error}>{ error }</li>)}
              </ul>
            </Modal>
          </span> :
          <span>{ isLoading && <StatusSpinner /> } { statusText }</span>
      }
    </div>
  )
}

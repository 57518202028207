import { useCallback, useMemo, useState } from "react"
import { useMsal } from "@azure/msal-react"
import ProcessFlowStep from "../../components/process/ProcessFlowStep"
import ProcessButton from "../../components/process/ProcessButton"
import LiveProcessStatusIndicator from "../../components/LiveProcessStatusIndicator"
import { useProcessStatus } from "../../contexts/ProcessStatus"
import { get, sendDelete } from "../../lib/api"
import Modal from "../Modal"
import StaticStatusMessage from "../../components/StaticStatusMessage"

export default function FreightChargeGapsExportData() {

  const { freightChargeGaps, dataMaintenance } = useProcessStatus()
  const { currentStatus, statusText } = freightChargeGaps
  const { activeStep, stepHistory } = currentStatus
  const { step, start, end, errors } = activeStep
  const { instance, accounts } = useMsal()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ tempStatus, setTempStatus ] = useState(null)
  const [ showRestartNotification, setShowRestartNotification ] = useState(false)

  const isComplete = useMemo(() => stepHistory.find(step => step.step === 'exportCsv'), [stepHistory])

  const onFileDownload = useCallback(async () => {
    setIsLoading(true)
    setTempStatus('Export in progress...')
    
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await get('freight-charge-gaps', idToken)
    if (response.ok) {
      const fileData = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(fileData)
      downloadLink.download = response.headers.get('file')
      downloadLink.click()
    }
    setIsLoading(false)
  }, [instance, accounts])

  const onProcessRestart = useCallback(async () => {
    setShowRestartNotification(true)
  }, [])

  const onClose = useCallback(e => {
    e.stopPropagation()
    setShowRestartNotification(false)
  }, [])

  const onRestartConfirm = useCallback(async () => {
    setIsLoading(true)
    setTempStatus('Restarting process...')
    setShowRestartNotification(false)
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await sendDelete(`freight-charge-gaps/${currentStatus.id}`, idToken)
    if (response.ok) await onFileDownload()
  }, [accounts, currentStatus.id, instance, onFileDownload])

  return (
    <ProcessFlowStep 
      stepText='1. Export Freight Charge Gaps Data' 
      isActive={step !== 'importCsv'} 
      isHistorical={isComplete}>
      { 
        isComplete && 
        <StaticStatusMessage>
          { isComplete.step === 'exportCsv' ? 'Export' : 'Import' } last completed by { isComplete.end.user }
          <br />
          { new Date(isComplete.end.time).toLocaleString() }
          <br />
          { isComplete.file }
        </StaticStatusMessage>
      }
      <ProcessButton 
        buttonText='Export Data' 
        handler={isComplete ? onProcessRestart : onFileDownload} 
        isDisabled={dataMaintenance.isLoading || isLoading || (start && !end && !errors)} />
      { 
        (!isComplete || isLoading) && 
        <LiveProcessStatusIndicator 
          type='process' 
          isLoading={isLoading || (start && !end && !errors)} 
          errors={isLoading ? [] : errors}
          statusText={tempStatus || statusText} /> 
      }
      <Modal show={showRestartNotification} onClose={onClose} type='confirm' onConfirm={onRestartConfirm} headerText={'Warning'}>
        <span>This will restart the current process.<br />Do you wish to export the data again?</span>
      </Modal> 
  </ProcessFlowStep>

  )
}
import { createContext, useContext, useReducer } from "react";
import { loadVendors, mapVendor, updateChargeType } from "./actions/vendorData";

const VendorDataContext = createContext(null)
const VendorDataDispatchContext = createContext(null)

/**
 * Provides nested children with access to Vendor data and dispatch contexts.
 * @param {Object} props
 * @param {any} props.children Child elements. 
 * @returns 
 */
export function VendorDataProvider({ children }) {
  const [ vendorData, dispatch ] = useReducer(vendorDataReducer, initialState)

  return (
    <VendorDataContext.Provider value={vendorData}>
      <VendorDataDispatchContext.Provider value={dispatch}>
        { children }
      </VendorDataDispatchContext.Provider>
    </VendorDataContext.Provider>
  )
}

/**
 * Returns vendor data context.
 * @returns 
 */
export function useVendorData() {
  return useContext(VendorDataContext)
}

/**
 * Returns vendor data dispatch context.
 */
export function useVendorDataDispatch() {
  return useContext(VendorDataDispatchContext)
}

function vendorDataReducer(state, action) {
  switch(action.type) {
    case "LOAD_VENDORS": return loadVendors(state, action)
    case "SET_CHARGE_TYPE": 
      const vendorIsMapped = !![
        ...state.mappedVendors, 
        ...state.unmappedVendors
      ].find(vendor => vendor.id === action.payload.id).CHARGE_TYPE
      if (vendorIsMapped) return updateChargeType(state, action)
      return mapVendor(state, action)
    default: return state
  }
}

const initialState = {
  unmappedVendors: [],
  mappedVendors: []
}
import { ReactComponent as FergusonLogo } from '../assets/Ferguson_RGB.svg'

export default function NoAccess() {
  return (
    <div className='no-access'>
      <FergusonLogo height={150} />
      <h1>Landed Cost</h1>
      <h3>UNAUTHORIZED</h3>
    </div>
  )
}
import { useCallback, useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiMenu, mdiClose } from '@mdi/js'
import { Link } from 'react-router-dom'
import NavMenuLink from '../components/NavMenuLink'
import { ReactComponent as FergusonLogo } from '../assets/Ferguson_RGB.svg'
import { useFetch } from '../hooks/useFetch'
import { useVendorData, useVendorDataDispatch } from '../contexts/VendorData'
import { useProcessStatus, useProcessStatusDispatch } from '../contexts/ProcessStatus'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket'
import Helmet from 'react-helmet'

/**
 * Renders a hamburger icon that opens a nav menu on click.
 * @returns 
 */
export default function NavMenu() {
  const [ isActive, setIsActive ] = useState(false)
  const onClick = useCallback(() => {
    setIsActive(!isActive)
  }, [isActive])
  const onMouseLeave = useCallback(() => {
    setTimeout(() => setIsActive(false), 200)
  }, [])
  const { estimatedImportCosts, freightChargeGaps, approvals } = useProcessStatus()
  const { statusText: estimatedImportCostsStatus } = estimatedImportCosts
  const { statusText: freightChargeGapsStatus } = freightChargeGaps
  const { statusText: approvalsStatus } = approvals
  const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, { share: true })
  const vendorDispatch = useVendorDataDispatch()
  const processStatusDispatch = useProcessStatusDispatch()
  const { unmappedVendors } = useVendorData()

  const { value, isLoading } = useFetch('vendors', { vendors: [] })

  useEffect(() => {
    processStatusDispatch({
      type: "SET_IS_LOADING",
      payload: { process: "vendorMapping", status: isLoading }
    })
    vendorDispatch({
      type: "LOAD_VENDORS",
      payload: { vendors: value?.vendors || [] }
    })
  }, [vendorDispatch, processStatusDispatch, value, isLoading])

  useEffect(() => {
    if (!lastJsonMessage) return
    
    const {
      initialOrchestrationStatusLoad,
      orchestrationStatusUpdate,
      orchestrationFinishedUpdate
    } = lastJsonMessage

    if (initialOrchestrationStatusLoad) {
      processStatusDispatch({ type: 'LOAD_ALL_STATUSES', payload: initialOrchestrationStatusLoad })
    }

    if (orchestrationStatusUpdate) {
      processStatusDispatch({ type: 'UPDATE_CURRENT_STATUS', payload: {
        process: orchestrationStatusUpdate.process,
        status: orchestrationStatusUpdate.status
      }})
    }

    if (orchestrationFinishedUpdate) {
      processStatusDispatch({ type: 'UPDATE_PREVIOUS_STATUS', payload: {
        process: orchestrationFinishedUpdate.process,
        status: orchestrationFinishedUpdate.previousStatus
      }})
    }
  }, [lastJsonMessage, processStatusDispatch])

  return (
    <div className='nav-menu-container' onMouseLeave={onMouseLeave}>
      <Helmet>
        <meta name="version" content={process.env.REACT_APP_BUILD_NO}/>
      </Helmet>
      <button className='nav-menu-icon' onClick={onClick}>
        { isActive ? <Icon path={mdiClose} size={2} /> : <Icon path={mdiMenu} size={2} /> }
      </button>
      <Link to={'/'}><FergusonLogo height={96} /></Link>
      {
        isActive && 
        <div className='nav-menu'>
          <Link to='/'><button>Overview</button></Link>
          <NavMenuLink link='freight-charge-gaps' displayText='Freight Charge Gaps' statusText={freightChargeGapsStatus} />
          <NavMenuLink link='approvals-replacement-costs' displayText='Approvals & Replacement Costs' statusText={approvalsStatus} />
          <NavMenuLink link='vendor-mapping' displayText='Vendor Mapping' statusText={`${unmappedVendors.length} unmapped vendors`} />
          <NavMenuLink link='estimated-import-costs' displayText='Estimated Import Costs' statusText={estimatedImportCostsStatus} />
        </div>
      }
    </div>
  )
}
import { useMemo } from "react"

export default function StaticStatusMessage({ type, statusText, children }) {
  
  const isError = useMemo(() => type === 'error', [type])

  return (
    <div className={`status-indicator ${isError ? 'error' : ''} ${type === 'overview' ? 'overview-status' : ''}`}>
      { children || <span>{ statusText }</span> }
    </div>
  )
}
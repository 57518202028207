import { useMsal } from "@azure/msal-react";
import ProcessFlowStep from "../../components/process/ProcessFlowStep";
import { useProcessStatus, useProcessStatusDispatch } from "../../contexts/ProcessStatus";
import { useCallback, useMemo, useState, useEffect } from "react";
import ProcessFilepicker from "../../components/process/ProcessFilepicker";
import { put } from "../../lib/api";
import LiveProcessStatusIndicator from "../../components/LiveProcessStatusIndicator";
import StaticStatusMessage from "../../components/StaticStatusMessage";

export default function FreightChargeGapsImportData({ completionHandler }) {

  const { freightChargeGaps, dataMaintenance } = useProcessStatus()
  const { currentStatus, statusText } = freightChargeGaps
  const { activeStep, stepHistory } = currentStatus
  const { start, end, errors, errorCount } = activeStep
  const processStatusDispatch = useProcessStatusDispatch()
  const { instance, accounts } = useMsal()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ tempStatus, setTempStatus ] = useState(null)
  const [ importFile, setImportFile ] = useState(null)
  const [ uploadErrors, setUploadErrors ] = useState(null)

  const isComplete = useMemo(() => stepHistory.find(step => step.step === 'importCsv'), [stepHistory])
  const exportStep = useMemo(() => stepHistory.find(step => step.step === 'exportCsv'), [stepHistory])

  useEffect(() => { if (isComplete) completionHandler() }, [isComplete, completionHandler])

  const onFileChange = useCallback(e => {
    setImportFile(e.target.files[0])
  }, [])

  const onFileUpload = useCallback(async () => {
    if (importFile === null) return
    setUploadErrors(null)
    setIsLoading(true)
    setTempStatus('Import in progress...')
    const { errors, errorCount, ...activeStepData } = activeStep
    if (!activeStepData.hasOwnProperty('start')) activeStepData.start = {}
    processStatusDispatch({ 
      type: 'UPDATE_CURRENT_STATUS', 
      payload: { 
        process: 'freightChargeGaps',
        status: { ...currentStatus, activeStep: activeStepData } 
      }})
    const fileData = new FormData()
    fileData.append('file', importFile, importFile.name)
    const { idToken } = await instance.acquireTokenSilent({ account: accounts[0] })
    const response = await put('freight-charge-gaps/upload', idToken, fileData)
    if (!response.ok) {
      const json = await response.json()
      setUploadErrors([json.error])
    }
    setImportFile(null)
    document.querySelector('.process-filepicker input').value = null
    setTempStatus(null)
    setIsLoading(false)
  }, [importFile, instance, accounts, activeStep, currentStatus, processStatusDispatch])

  return (
    <ProcessFlowStep 
      stepText='2. Import Freight Charge Gaps Data' 
      isActive={currentStatus.activeStep.step === 'importCsv'} 
      isHistorical={currentStatus.stepHistory.find(step => step.step === 'importCsv')}>
        {
          exportStep ?
            <StaticStatusMessage>
              { exportStep.step === 'exportCsv' ? 'Export' : 'Import' } last completed by { exportStep.end.user }
              <br />
              { new Date(exportStep.end.time).toLocaleString() }
              <br />
              { exportStep.file }
            </StaticStatusMessage> : <></>
        }
        <ProcessFilepicker 
          fileName={importFile?.name || null}
          cancelHandler={() => setImportFile(null)} 
          changeHandler={onFileChange} 
          uploadHandler={onFileUpload} 
          isDisabled={dataMaintenance.isLoading || importFile === null || isLoading || (start && !end && !errors)} />
        { 
          !isComplete && 
          <LiveProcessStatusIndicator 
            type='process'
            isLoading={isLoading || (start && !end && !errors)}
            errors={isLoading ? [] : uploadErrors || errors}
            errorCount={errorCount}
            statusText={tempStatus || statusText}  /> 
        }
    </ProcessFlowStep>
  )
}
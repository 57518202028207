export function setIsLoading(state, action) {
  const { payload } = action
  const { process: processName, status } = payload
  return {
    ...state,
    [processName]: {
      ...state[processName],
      isLoading: status
    }
  }
}

export function loadAllStatuses(state, action) {
  const { payload } = action
  const { estimatedImportCosts, freightChargeGaps, approvals } = payload
  return {
    ...state,
    estimatedImportCosts: {
      ...estimatedImportCosts,
      statusText: getStatusText('estimatedImportCosts', estimatedImportCosts.currentStatus)
    },
    freightChargeGaps: {
      ...freightChargeGaps,
      statusText: getStatusText('freightChargeGaps', freightChargeGaps.currentStatus)
    },
    approvals: {
      ...approvals,
      statusText: getStatusText('approvals', approvals.currentStatus)
    }
  }
}

export function updateDataRefreshStatus(state, action) {
  const { payload } = action
  return {
    ...state,
    dataMaintenance: {
      isLoading: payload
    }
  }
}

export function updateProcessCurrentStatus(state, action) {
  const { payload } = action
  const { process: processName, status } = payload
  if (processName === 'dataMaintenance') {
    return {
      ...state,
      dataMaintenance: {
        isLoading: true
      }
    }
  } else {
    return {
      ...state,
      [processName]: {
        ...state[processName],
        currentStatus: status,
        statusText: getStatusText(processName, status)
      }
    }  
  }
}

export function updateProcessPreviousStatus(state, action) {
  const { payload } = action
  const { process: processName, status } = payload
  if (processName === 'dataMaintenance') {
    return {
      ...state,
      dataMaintenance: {
        isLoading: false
      }
    }
  } else {
    return {
      ...state,
      [processName]: {
        ...state[processName],
        previousStatus: status,
        currentStatus: { id: null, activeStep: {}, stepHistory: [], processType: null },
        statusText: getStatusText(processName, status)
      }
    }  
  }
}

function getStatusText(processName, status) {
  const { activeStep } = status
  const { step, start, end, errors } = activeStep
  switch (processName) {
    case 'estimatedImportCosts':
    case 'freightChargeGaps':
      if (step === 'importCsv') {
        if (!start) return 'Ready for import'
        if (start && !end && !errors) return 'Import in progress...'
        if (start && !end && errors) return 'Import errors encountered'
      } else if (step === 'exportCsv') {
        if (!start) return 'Ready for export'
        if (start && !end && !errors) return 'Export in progress...'
        if (start && !end && errors) return 'Export errors encountered'
      } else {
        return 'Ready for export'
      }
      break
    case 'approvals':
      if (step === 'pushTrilogie') {
        if (!start) return 'Ready to send replacement costs to Trilogie'
        if (start && !end && !errors) return 'Sending replacement cost file to Trilogie'
        if (start && !end && errors) return errors[0]
      } else if (step === 'importCsv') {
        if (!start) return 'Ready for import'
        if (start && !end && !errors && activeStep.skipped) return 'Skipping to approval step...'
        if (start && !end && !errors) return 'Import in progress...'
        if (start && !end && errors) return 'Import errors encountered'
      } else if (step === 'exportCsv') {
        if (!start) return 'Ready for export'
        if (start && !end && !errors) return 'Export in progress...'
        if (start && !end && errors) return 'Export errors encountered'
      } else if (step === 'calculation') {
        if (!start) return 'Ready to run calculations'
        if (start && !end && !errors) return 'Calculating landed and replacement costs'
        if (start && !end && errors) return errors[0]
      } else {
        return 'Ready to run calculations'
      }
      break
    default:
      return ''
  }
}
import DataGridHeaderCell from "./DataGridHeaderCell";

/**
 * Renders a DataGridHeaderCell for each VendorMapping column.
 * @returns 
 */
export default function DataGridHeaderRow() {
  return (
    <tr className='data-grid-header-row'>
      <DataGridHeaderCell content='Logon' />
      <DataGridHeaderCell content='Vendor ID' />
      <DataGridHeaderCell content='Vendor' />
      <DataGridHeaderCell content='Charge Type' />
      <DataGridHeaderCell content='Created By' />
      <DataGridHeaderCell content='Created Date' />
      <DataGridHeaderCell content='Updated By' />
      <DataGridHeaderCell content='Updated Date' />
    </tr>
  )
}
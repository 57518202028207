import { Link } from "react-router-dom"

/**
 * Renders a button that links to the provided endpoint.
 * @param {Object} props
 * @param {string} props.link Destination endpoint.
 * @param {string} props.displayText Link title.
 * @param {string} props.statusText Additional status information.
 * @returns 
 */
export default function NavMenuLink({ link, displayText, statusText }) {
  return (
    <Link to={ link }>
      <button>
        { displayText }
        <br />
        <em>{ statusText }</em>
      </button>
    </Link>
  )
}
import MaintenanceHistoryLine from "./MaintenanceHistoryLine";

/**
 * Renders a table with a row for each maintenance step.
 * @param {Object} props
 * @param {string} props.version Maintenance history version.
 * @param {MaintenanceStep[]} props.maintenanceSteps Array of maintenance steps.
 * @returns 
 */
export default function MaintenanceHistory({ version, maintenanceSteps }) {

  return (
    <div className='maintenance-history'>
      <h4>{ version }</h4>
      <table>
        <tbody>
          {
            maintenanceSteps.map(maintenanceStep => <MaintenanceHistoryLine key={maintenanceStep.stepText} maintenanceStep={maintenanceStep} />)
          }
        </tbody>
      </table>
    </div>
  )
}
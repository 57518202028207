export function loadVendors(state, action) {
  return {
    ...state,
    unmappedVendors: action.payload.vendors.filter(vendor => !vendor.CHARGE_TYPE),
    mappedVendors: action.payload.vendors.filter(vendor => vendor.CHARGE_TYPE)
  }
}

export function mapVendor(state, action) {
  const { id, CHARGE_TYPE, CREATED_BY, CREATED_DATE } = action.payload
  const unmappedVendor = state.unmappedVendors.find(vendor => vendor.id === id)
  return {
    ...state,
    unmappedVendors: state.unmappedVendors.filter(vendor => vendor.id !== id),
    mappedVendors: [{
      ...unmappedVendor,
      CHARGE_TYPE,
      CREATED_BY,
      CREATED_DATE
    }, ...state.mappedVendors.filter(vendor => vendor.id !== id)]
  }
}

export function updateChargeType(state, action) {
  const { id, CHARGE_TYPE, UPDATED_BY, UPDATED_DATE } = action.payload
  const updatedVendor = state.mappedVendors.find(vendor => vendor.id === id)
  return {
    ...state,
    mappedVendors: state.mappedVendors.map(vendor => {
      if (vendor.id !== id) return vendor
      return {
        ...updatedVendor,
        CHARGE_TYPE,
        UPDATED_BY,
        UPDATED_DATE
      }
    })
  }
}